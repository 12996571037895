import * as React from "react"
import Layout from "../../components/layout"
import Servicelinks from "../../components/servicelinks"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'
import useIntersection from '../../components/useIntersection'; // import the custom hook
import classNames from 'classnames';

const Section = ({  id, className, children }) => {
  const [ref, isIntersecting] = useIntersection({
    root: null, // Use the viewport as the root
    rootMargin: '-90px',
    threshold: 0.3 // Trigger when 50% of the element is in view
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting })}
    >
      {children}
    </section>
  );
};


const ecommerceDev = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'service-page'
    }}
/>
  <Layout pageTitle="ecommerceDev">
    <section id="main">
      <div id="service-intro">
        <div className="left-col">
          <h1><span className="revealblack">Ecommerce Development</span></h1>
<p><span className="revealblack">Here at Tobstar’s eCommerce development, we build eCommerce websites with a powerful, open-source content management system either in Magento, Shopify or Woocommerce.</span></p>
          <a className="button sendusemail" href="#sendusemail"><span className="seemore"><i>&#xe801;</i></span>Send us an email</a>
        </div>
        <div className="right-col">
            <img className="picture" src={'/img/services/tobstar-ecommerce-header.jpg'} alt="Ecommerce Development" />
        </div>
      </div>
    </section>

    <Section id="service-content">
      <div id="inner-content">
<h2>Magento, Shopify or WooCommerce?</h2>
<p>Depends on your business requirements, please contact Tobstar to make a recommendation for your business and provide options. Magento is totally eCommerce driven management system and Woocommerce is an extension plugin add-on to <AniLink cover to="/capabilities/wordpress-development-melbourne" bg="#00ccd4" direction="right" duration={0.76}>WordPress</AniLink>.</p>
<p>It’s a simple answer, if your business is providing online shopping with a lot of products and categories then Magento is the best solution for large shopping sites. Magento is flexibility and open source.</p>
<p>WooCommerce is the best ecommerce platform for add-on to your Wordpress website because of its flexibility and ease of use. Customisations are limitless due to WooCommerce being open source. Easily update product information and get useful features  like order tracking, customer engagement, delivery status updates, inventory management, and more.</p>
<p>Shopify is a subscription-based eCommerce platform that helps businesses of all sizes sell physical and digital products.</p>
<p>Development and hosting costs are very different between them, with Magento being eCommerce heavyweight and Woocommerce a lightweight.</p>
<p>We also customise eCommerce development for online purchasing, forms to make payment for products, services or donations. Online shopping is an efficient and reliable way to buy and sell products and services, and people like it. It’s easy and it works.</p>


      </div>
        <Servicelinks></Servicelinks>
  </Section>
</Layout>

  </>
  )
}

export default ecommerceDev


export const Head = () => (
  <>
    <title>eCommerce Development Melbourne - Tobstar</title>
<meta name="description" content="With eCommerce development, we build eCommerce websites with a powerful, open-source content management system either in Magento or Woocommerce." />
  </>
)