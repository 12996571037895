import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Serviceslinks() {
  return (
  	<div className="seemoreservices">
      <AniLink cover to="/capabilities/web-development-melbourne" bg="#00ccd4" direction="right" duration={0.76} className="button seemoreservice" href="#service"><span className="seemore"><i>&#xe801;</i></span>Web Development</AniLink>
      <AniLink cover to="/capabilities/wordpress-development-melbourne" bg="#00ccd4" direction="right" duration={0.76} className="button seemoreservice" href="#service"><span className="seemore"><i>&#xe801;</i></span>Wordpress Development</AniLink> 
      <AniLink cover to="/capabilities/ecommerce-development-melbourne" bg="#00ccd4" direction="right" duration={0.76} className="button seemoreservice" href="#service"><span className="seemore"><i>&#xe801;</i></span>Ecommerce Development</AniLink> 
      <AniLink cover to="/capabilities/app-development-melbourne" bg="#00ccd4" direction="right" duration={0.76} className="button seemoreservice" href="#service"><span className="seemore"><i>&#xe801;</i></span>Mobile and App Development</AniLink>
      <AniLink cover to="/capabilities/api-development-melbourne" bg="#00ccd4" direction="right" duration={0.76} className="button seemoreservice" href="#service"><span className="seemore"><i>&#xe801;</i></span>API Integration Development</AniLink>
      <AniLink cover to="/capabilities/search-engines-optimisation" bg="#00ccd4" direction="right" duration={0.76} className="button seemoreservice" href="#service"><span className="seemore"><i>&#xe801;</i></span>Search Engine Optimisation </AniLink>
      <AniLink cover to="/capabilities/private-server-web-hosting-melbourne" bg="#00ccd4" direction="right" duration={0.76} className="button seemoreservice" href="#service"><span className="seemore"><i>&#xe801;</i></span>Private Server and Web Hosting</AniLink>
      </div>
  )
}